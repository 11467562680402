/* global google, base_url */
var $site = {
	init: function() {
		this.prettySelects();
		this.toggleSubMenu();
		this.toggleMenu();
		this.fixLabels();
		this.iconHome();
		this.homeHeader();
		this.contactMap();
		this.rotatorioFooter();
		this.cookies();
		this.iPadHover();
		this.socialButtons.init();
		this.paging.init();
		this.sticky();
		this.filterByBrand();
	},
	socialButtons: {
		buttonsSelector: '.share_us a',
		init: function () {
			var facebookurl = 'https://www.facebook.com/sharer/sharer.php?u=';
			var twitterurl = 'https://twitter.com/home?status=';
			var googleurl = 'https://plus.google.com/share?url=';
			var pinurl = 'http://pinterest.com/pin/create/button/?url=';
			$(document).on('click', this.buttonsSelector , function () {
				var $this = jQuery(this);
				var url;
				var share_url = ($this.data('url') != '' && $this.data('url') != undefined) ? $this.data('url') : location.href;
				if ( $this.hasClass('facebook') ) {
					url = facebookurl + share_url;
					$this.attr('href', url);
				} else if ( $this.hasClass('twitter') ) {
					url = twitterurl + share_url;
					$this.attr('href', url);
				} else if ( $this.hasClass('google') ) {
					url = googleurl + share_url;
					$this.attr('href', url);
				} else if ( $this.hasClass('pinterest') ) {
					url = pinurl + share_url;
					$this.attr('href', url);
				}
				$this.attr('target', '_blank');
			});
		}
	},
	cookies: function() {
		var lopdok = $.cookie('lopd_cookie');
		var $precookies = $('#precookies');
		if (!lopdok) {
			$.cookie('lopd_cookie', '1', {
				expires: 365,
				path: '/'
			});
			$precookies.show().on('click', function() {
				$(this).fadeOut();
			});
		} else {
			$precookies.remove();
		}
	},
	rotatorioFooter: function() {
		if ( $('.logosLower ul').length ) {
			$('.logosLower ul').slick({
				autoplay: true,
				dots: false,
				infinite: true,
				speed: 500,
				slidesToShow: 5,
				slidesToScroll: 5,
				arrows: false,
			});
		}
	},
	contactMap: function() {
		if ($('body').hasClass('contacto-page')) {
			var mapOptions = {
				zoom: 13,
				center: new google.maps.LatLng( 43.318334, -1.981231 ),
				scrollwheel: false,
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				disableDefaultUI: true
			};
			var mapBounds = new google.maps.LatLngBounds();
			var map = new google.maps.Map(document.getElementById("map"), mapOptions);
			var marker_1 = new google.maps.Marker({
				position: new google.maps.LatLng(43.317457, -1.983973),
				map: map,
				icon: 'img/marker.png'
			});
			mapBounds.extend(new google.maps.LatLng(43.317457, -1.983973));
			var marker_2 = new google.maps.Marker({
				position: new google.maps.LatLng(43.324742, -1.972812),
				map: map,
				icon: 'img/marker.png'
			});
			mapBounds.extend(new google.maps.LatLng(43.324742, -1.972812));
			var marker_3 = new google.maps.Marker({
				position: new google.maps.LatLng(43.315955, -1.904291),
				map: map,
				icon: 'img/marker.png'
			});
			mapBounds.extend(new google.maps.LatLng(43.315955, -1.904291));
			var styles = [{
				"stylers": [{
					"saturation": -100
				}]
			}];
			marker_1.setMap(map);
			marker_2.setMap(map);
			marker_3.setMap(map);
			map.setOptions({
				styles: styles
			});
			map.fitBounds(mapBounds);
			$(window).resize(function () {
				map.fitBounds(mapBounds);
			});
		}
	},
	homeHeader: function() {
		if ($('body').hasClass('home-page')) {
			$('.cabecera').height($(window).height() - 22);
			$(window).resize(function() {
				$('.cabecera').height($(window).height() - 22);
			});
		}
	},
	iconHome: function() {
		$('.icon-chevron-thin-down').on('click', function() {
			$('html,body').animate({
				scrollTop: $(window).height()
			}, 1500);
		});
	},
	placeMenu: function() {
		var margen_start = ($('#fixedMenu').outerHeight()) - $('#upper').height();
		$('nav').css('top', margen_start / 2);
		$(window).resize(function() {
			var margen = ($('#fixedMenu').outerHeight()) - $('#upper').height();
			$('nav').css('top', margen / 2);
		});
	},
	prettySelects: function($wrapper) {
		$('.prettySelects', $wrapper).each(function() {
			var $this = $(this);
			var $select = $('select', $this);
			var $texto = $('<div class="text">' + $select.find(':selected').text() + '</div>');
			$select.on('change redraw', function() {
				$texto.html($(this).find(':selected').text());
			});
			$texto.appendTo($this);
		});
	},
	cookieMsg: function() {
		var cookie = $.cookie('cookiemsg');
		if (!cookie || cookie == undefined) {
			$('<div>').attr('id', 'precookies').hide().appendTo('body');
			$('#precookies').load(base_url + 'popup-cookies').on('click', function() {
				$('#precookies').slideUp().remove();
			}).slideDown();
			$.cookie('cookiemsg', true);
		}
	},
	fixLabels: function($wrapper) {
		$('.overlabels', $wrapper).each(function() {
			var $this = $(this);
			var field = $this.find('[type=text], [type=email], [type=password], textarea');
			var span = $(this).find('> span');
			var onBlur = function() {
				if ($.trim(field.val()) == '') {
					field.val('');
					span.fadeIn(100);
				} else {
					span.fadeOut(100);
				}
			};
			field.focus(function() {
				span.fadeOut(100);
			}).blur(onBlur);
			onBlur();
		});
	},
	toggleMenu: function() {
		var $fixedMenu = $('#fixedMenu');
		$('.standMenu').on("click", function() {
			$('.icon-chevron-thin-down').toggle();
			$('html').toggleClass('overflowed');
			if ( $fixedMenu.hasClass('opaco')) {
				$('.icon-x').fadeOut();
				$('#literalMenu').fadeIn();
				$fixedMenu.hide().animate({ opacity: 0 }, 250).removeClass('opaco');
				$('.lines').fadeIn();
			} else {
				$('.lines').fadeOut();
				$('#literalMenu').fadeOut();
				$fixedMenu.show().animate({
					opacity: 1
				}, 250).addClass('opaco');
				$('.icon-x').fadeIn();
			}
			$('#submenu').removeClass('opaco');
			$('#upper ').removeClass('open').show();
			$(this).toggleClass('menuToggle');
		});

	},
	iPadHover: function() {
		$('.box').on("touchend", function(e) {
			'use strict';
			var link = $(this);
			if (link.hasClass('hover')) {
				return true;
			} else {
				link.addClass('hover');
				$('.box').not(this).removeClass('hover');
				e.preventDefault();
				return false;
			}
		});
	},
	toggleSubMenu: function() {
		$('.menuCloser').on('click', function() {
			$('#upper').show();
			$('#submenu').remove('opaco');
			$('.menuCloser').hide();
		});
		$('#upper li').eq(1).on("click", function() {
			$('#submenu').addClass('opaco');
			if ($(window).width() <= 480) {
				$('#upper').hide();
				$('.menuCloser').show();
			}
			if (($(window).width() < 960) && ($(window).width() > 480)) {
				$('#upper ').toggleClass('open');
			}
		});
	},
	paging: {
		container: $('.js-where-to-load'),
		element: $('.js-load-more'),
		load: function () {
			var self = this;
			self.element.on('click sclick', function (e) {
				e.preventDefault();
				var $this = $(this);
				$.get ( $this.attr('href'), function (data) {
					var $new = $(data).find( '.js-when-loaded' );
					var $new_element = $(data).find( '.js-load-more' );
					self.container.append ( $new );
					if ( $new_element.length ) {
						self.element.attr('href', $new_element.attr('href') );
					} else {
						self.element.remove();
					}
				});
			});
		},
		init: function () {
			var self = this;
			if ( self.element.length ) {
				self.load();
			}
		}
	},
	sticky: function () {
		var target = 75 /*$('header').outerHeight(true)*/;
		$(window).on('scroll' , function () {
			var top = $(this).scrollTop();
			if ( top >= target  ) {
				$('html').addClass('sticky');
			} else {
				$('html').removeClass('sticky');
			}
		});
	},
	filterByBrand: function () {
		$('select[name=brand]').on('change', function () {
			var $this = $(this);
			location.href = $this.data('page-url') + $this.val();
		});
	}
};
$(function() {
	$site.init();
});